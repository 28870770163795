body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.toggle{
  /* h="22px" bg="#fff" width={"48px"} rounded="22px" boxShadow={"inset 0px 0px 2px 1px rgba(23, 106, 90, 0.35)"} p="3px" */

  height: 22px;
  background-color: #fff;
  width: 48px;
  border-radius: 22px;
  box-shadow: inset 0px 0px 2px 1px rgba(23, 106, 90, 0.35);
  padding: 3px;

}
.toggle-on{
  background-color: #CCF9F0;
}
.toggle-disable{
  background-color: #fff;
}
.disable{
  background-color: red;
}

.toggle-btn{
  /* h="16px" float={item.Verified == true ? "right" : "left"} width={"16px"} rounded="100%" bg="#50BFA9" boxShadow={"0px 0px 3px 3px rgba(0, 0, 0, 0.08)"} */

  height: 16px;
  width: 16px;
  border-radius: 100%;
  background-color: #A3A3A3;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.08);
}

.on{
  float: right;
  background: #00FFCD;
}

.off{
  float: left;
}
.disable-off{
  float: left;
  background: red
}

.progress{
  width: 100%;
  background: #F1F1F1;
  height: 7px;
  margin-top: 3px;
  border-radius: 4px;
}
.progress-bar{
  border-radius: 4px;

}

.label{
  text-transform: capitalize;
  float: right;
  font-weight: 400;
}

.drop-down{
  position: absolute;
  width: 232px;
  background: rgba(244, 244, 244, 0.95);
  border: 0.2px solid #939393;
  box-shadow: 1px 2px 1px 1px rgba(0, 0, 0, 0.01);
  top: 60px;
  left: -185px;
  padding: 15px;
  cursor: pointer;
  z-index: 44;
  
}

.flex-icon{
  height: 30px;
  width: 30px;
  background: #E02828;
  border-radius: 100%;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #c5d1ee;
  border-radius: 80px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(269.11deg, #50FCDA 19.49%, #12CDA8 87.44%); 
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00474F; 
}  ::-webkit-scrollbar {
  width: 4px;
}

html {
  scroll-behavior: smooth;
}

.progress-bar-2{
  display: flex;
  justify-content: space-between;
  margin-top: 2px;


}
.progress-bar-2 span {
  background: #A3A3A3;
  
}
.preloader{
  display: flex;
  justify-content: center;
}

.preloader::after{
  content: "";
  width: 120px;
  height: 120px;
  border: 10px solid #01896e;
  border-top-color: #00FFCD;
  border-bottom-color: #00FFCD;
  border-radius: 100%;
  animation: loading 1s linear infinite ;

}

@keyframes loading {
  to {
    transform: rotate(1turn);
  }
}

.glow, .glow2, .glow3 {
  display: flex;
  justify-content: center;
}

.glow {
  width: 200px;
  height: 200px;
  border: 15px solid #EB7B62;
  border-radius: 100%;
  animation: glow 1s linear infinite;
}
.glow2 {
  width: 230px;
  height: 230px;
  border: 15px solid #FFD1C7;
  transform: scale();
  border-radius: 100%;
}

.label2{
  width: 35px;
  height: 35px;
  background-color: #56504F;
  border: 2px solid #D7D2D1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FAFAFA;
  font-size: 22px;
  
}


/* @keyframes glow {
  from {
    width: 350px;
    height: 350px;
  }
  to {
    width: 0px;
    height: 0px;
  }
} */
